import { prefersReducedMotion } from '@mrhenry/wp--prefers-reduced-motion';

let allowAutomatedScroll = true;

const contentObserver: IntersectionObserver = new IntersectionObserver( ( entries ) => {
	for ( const entry of entries ) {
		if ( !( entry.target instanceof HTMLElement ) ) {
			return;
		}

		if ( entry.isIntersecting ) {
			allowAutomatedScroll = false;
		}
	}
}, {
	threshold: 1,
} );

function clickHandler( e: MouseEvent ): void {
	// Check target.
	if ( !e.target || !( e.target instanceof HTMLElement ) ) {
		return;
	}

	// Click on play button: init source replace etc.
	if ( e.target.hasAttribute( 'landing-hero-play-button' ) ) {
		const videoId = e.target.getAttribute( 'for-id' );
		if ( !videoId ) {
			return;
		}

		initSourceReplace( e.target, videoId );

		e.preventDefault();
		e.stopPropagation();

		return;
	}

	// Click on video: toggle playback.
	if ( e.target.hasAttribute( 'landing-hero-video' ) && e.target instanceof HTMLMediaElement ) {
		if ( !e.target.paused ) {
			e.target.pause();

			return;
		}

		const playPromise = e.target.play();
		if ( playPromise && ( 'function' === typeof playPromise.catch ) ) {
			playPromise.catch( ( err ) => {
				console.log( err );
			} );
		}

		return;
	}
}

function initSourceReplace( button:HTMLElement, videoId: string ) {
	// Get video.
	const video = document.querySelector( `#${videoId}` );
	if ( !video || !( video instanceof HTMLMediaElement ) ) {
		return;
	}

	// Check src.
	const srcSound = video.getAttribute( 'src-with-sound' );
	if ( !srcSound || srcSound === video.src ) {
		return;
	}

	// Hide button & container.
	button.setAttribute( 'hidden', '' );
	button.setAttribute( 'disabled', '' );

	const buttonContainer = button.closest( '[landing-hero-play-button-container]' );
	if ( buttonContainer ) {
		buttonContainer.setAttribute( 'hidden', '' );
	}

	// Setup video for full video.
	video.loop = false;
	video.muted = false;
	const srcOriginal = video.getAttribute( 'src' );
	video.src = srcSound;

	const playPromise = video.play();
	if ( playPromise && ( 'function' === typeof playPromise.catch ) ) {
		playPromise.catch( ( err ) => {
			console.log( err );
		} );
	}

	// Reset everything after full video ends.
	video.addEventListener( 'ended', () => {
		// Show button & container again.
		button.removeAttribute( 'hidden' );
		button.removeAttribute( 'disabled' );
		if ( buttonContainer ) buttonContainer.removeAttribute( 'hidden' );

		// Reset video attributes.
		video.muted = false;
		video.loop = true;
		if ( srcOriginal ) video.src = srcOriginal;

		const replayPromise = video.play();
		if ( replayPromise && ( 'function' === typeof replayPromise.catch ) ) {
			replayPromise.catch( ( err ) => {
				console.log( err );
			} );
		}

		// Scroll to hero content.
		if ( !allowAutomatedScroll ) {
			return;
		}
		const scrollToEl = document.querySelector( '[landing-hero-content]' );
		if ( !scrollToEl || !( scrollToEl instanceof HTMLElement ) ) {
			return;
		}

		let scrollBehavior: ScrollBehavior = 'smooth';
		if ( prefersReducedMotion() ) {
			scrollBehavior = 'auto';
		}

		scrollToEl.scrollIntoView( {
			behavior: scrollBehavior,
			block: 'end',
		} );
	} );
}

window.addEventListener( 'click', clickHandler );

function initLandingHero() {
	const heroContent = document.querySelector( '[landing-hero-content]' );
	if ( !heroContent ) {
		return;
	}

	if ( !( 'IntersectionObserver' in window ) ) {
		allowAutomatedScroll = false;
	}

	contentObserver.observe( heroContent );
}

window.addEventListener( 'load', initLandingHero );
