export function isHasAudioTracksElement( element: HTMLElement ): element is HasAudioTracksElement {
	return ( 'addEventListener' in element ) &&
		(
			( 'mozHasAudio' in element ) ||
			( 'audioTracks' in element ) ||
			( 'webkitAudioDecodedByteCount' in element )
		);
}

export type HasAudioTracksElement = HTMLElement & {
	mozHasAudio?: boolean
	audioTracks?: AudioTrackList
	webkitAudioDecodedByteCount?: number
};

interface AudioTrackList {
	length: number
}
