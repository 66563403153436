const observedElements = new Set<HTMLElement>();
const anchorLinksMap = new WeakMap<HTMLElement, HTMLAnchorElement>();

function initAnchorStates() {
	observedElements.clear();

	const anchorLinks = document.querySelectorAll<HTMLAnchorElement>( 'a.sub-navigation-menu__list__link' );
	anchorLinks.forEach( ( anchorLink ) => {
		if ( !anchorLink.hash.slice( 1 ) ) {
			return;
		}
		if ( window.location.pathname !== anchorLink.pathname ) {
			return;
		}

		const anchoredEl = document.getElementById( anchorLink.hash.slice( 1 ) );
		if ( !anchoredEl ) {
			return;
		}

		observedElements.add( anchoredEl );
		anchorLinksMap.set( anchoredEl, anchorLink );
	} );
}

requestAnimationFrame( initAnchorStates );
document.addEventListener( 'DOMContentLoaded', () => {
	initAnchorStates();
} );

function scrollHandler() {
	let maxOverlap = 0;
	let maxOverlapAnchorLink: HTMLAnchorElement | null = null;

	for ( const element of observedElements ) {
		const anchorLink = anchorLinksMap.get( element );
		if ( !anchorLink ) {
			return;
		}

		anchorLink.classList.remove( 'sub-navigation-menu__list__link--is-active' );

		const anchorArea = getAnchorArea( element );
		const viewportArea = {
			top: window.pageYOffset || document.documentElement.scrollTop,
			bottom: ( window.pageYOffset || document.documentElement.scrollTop ) + ( window.innerHeight || document.documentElement.clientHeight ),
		};

		const currentOverlap = overlap( anchorArea.top, anchorArea.bottom, viewportArea.top, viewportArea.bottom );
		if ( currentOverlap > maxOverlap && 100 < currentOverlap ) {
			maxOverlap = currentOverlap;
			maxOverlapAnchorLink = anchorLink;
		}
	}

	if ( maxOverlapAnchorLink ) {
		maxOverlapAnchorLink.classList.add( 'sub-navigation-menu__list__link--is-active' );
	}
}

let lastScrollHandler = 0;
let scrollHandlerTimeOut = 0;
window.addEventListener( 'scroll', () => {
	window.clearTimeout( scrollHandlerTimeOut );
	scrollHandlerTimeOut = window.setTimeout( scrollHandler, 200 );

	const now = performance.now();
	if ( now > lastScrollHandler + 50 ) {
		lastScrollHandler = now;
		scrollHandler();
	}
} );

function getAnchorArea( el: HTMLElement ) {
	const nextAreaStart = el.parentElement?.querySelector( `#${el.id} ~ [id]` );

	if ( !nextAreaStart ) {

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const bottom = el.parentElement!.getBoundingClientRect().bottom;
		const top = el.getBoundingClientRect().top;

		return {
			top: top + window.pageYOffset || document.documentElement.scrollTop,
			bottom: bottom + window.pageYOffset || document.documentElement.scrollTop,
		};
	}

	const bottom = nextAreaStart.getBoundingClientRect().top;
	const top = el.getBoundingClientRect().top;

	return {
		top: top + window.pageYOffset || document.documentElement.scrollTop,
		bottom: bottom + window.pageYOffset || document.documentElement.scrollTop,
	};

}

function overlap( min1:number, max1:number, min2:number, max2:number ) {
	return Math.max( 0, Math.min( max1, max2 ) - Math.max( min1, min2 ) );
}
