window.addEventListener( 'load', () => {
	const textAreas = document.querySelectorAll( 'textarea[auto-grow]' ) as NodeListOf<HTMLTextAreaElement>;

	textAreas.forEach( ( el ) => {
		// Catch all. This ensures that we at least resize after we lose focus.
		el.addEventListener( 'change', function() {
			resizeTextArea( this );
		} );

		// Drop can be done without losing focus but doesn't have a keyup event.
		el.addEventListener( 'drop', function() {
			requestAnimationFrame( () => { /* needs a bit of delay */
				resizeTextArea( this );
			} );
		} );

		// Paste can be done multiple times while keeping "cmd" down.
		el.addEventListener( 'paste', function() {
			requestAnimationFrame( () => { /* needs a bit of delay */
				resizeTextArea( this );
			} );
		} );

		// Basic resizing.
		el.addEventListener( 'keyup', function() {
			resizeTextAreaThrottled( this );
		} );
	} );
} );

let ticker = 0;

function resizeTextAreaThrottled( el: HTMLTextAreaElement ): void {
	const modernNavigator = navigatorWithInputPending();
	if ( modernNavigator && modernNavigator.scheduling.isInputPending( {
		includeContinuous: false,
	} ) ) {
		// User is typing intensely. Let them continue before resizing.
		return;
	}

	if ( ticker ) {
		window.clearTimeout( ticker );
	}

	ticker = window.setTimeout( () => {
		resizeTextArea( el );
	}, 50 );
}

function resizeTextArea( el: HTMLTextAreaElement ): void {
	if ( !el.parentNode || !el.clientHeight ) {
		return;
	}

	if ( el.scrollHeight > el.clientHeight ) {
		el.style.height = 'auto';
		el.style.height = el.scrollHeight + 'px';
	}
}

function navigatorWithInputPending(): NavigatorWithScheduling | null {
	const x = window.navigator as MaybeNavigatorWithScheduling;
	if ( !x || !x.scheduling || !x.scheduling.isInputPending ) {
		return null;
	}

	return x as NavigatorWithScheduling;
}

interface MaybeNavigatorWithScheduling extends Navigator {
	scheduling?: {
		isInputPending?: ( options: IsInputPendingOptions ) => boolean
	}
}

interface NavigatorWithScheduling extends Navigator {
	scheduling: {
		isInputPending: ( options: IsInputPendingOptions ) => boolean
	}
}

interface IsInputPendingOptions {
	includeContinuous: boolean
}
