const headerObserver: IntersectionObserver = new IntersectionObserver( ( entries ) => {
	for ( const entry of entries ) {
		// Interpret as "visible" from "0.1", this gives a more natural transition.
		if ( entry.isIntersecting && 0.1 < entry.intersectionRatio ) {
			document.body.setAttribute( 'site-header-observed-element-is-visible', '' );
			setMetaThemeColor( true );
		} else {
			document.body.removeAttribute( 'site-header-observed-element-is-visible' );
			setMetaThemeColor( false );
		}


		// Only needed during load.
		// This prevents a flash.
		document.body.removeAttribute( 'site-header-theme-on-load' );
		document.body.removeAttribute( 'site-header-transparent-on-load' );
	}
}, {
	threshold: [
		0,
		0.05,
		0.1,
	],
} );

function initHeaderObserver() {
	const observed = document.querySelector( '[alter-site-header-while-this-is-visible]' );
	if ( !observed ) {
		return;
	}

	headerObserver.observe( observed );
}

initHeaderObserver();
requestAnimationFrame( () => {
	initHeaderObserver();
} );

window.addEventListener( 'load', () => {
	initHeaderObserver();
} );

function setMetaThemeColor( introIsVisible: boolean ) {
	const meta = document.querySelector( 'meta[name="theme-color"][intro-visible]' );
	if ( !meta ) {
		return;
	}

	if ( introIsVisible ) {
		const newColor = meta.getAttribute( 'intro-visible' );
		if ( !newColor ) {
			return;
		}

		meta.setAttribute( 'content', newColor );

		return;
	}

	const newColor = meta.getAttribute( 'intro-not-visible' );
	if ( !newColor ) {
		return;
	}

	meta.setAttribute( 'content', newColor );

	return;
}
