// MrMuteToggle is a self contained unmute/mute button for HTMLVideoElement
// supports :
// - mute
// - unmute

import { HasAudioTracksElement, isHasAudioTracksElement } from '../type-guards/has-audio-tracks-element';
import { MutedElement, isMutedElement } from '../type-guards/muted-element';

export class MrMuteToggle extends HTMLElement {
	/**
	 * "for" is observed.
	 * You can update this attribute in DOM.
	 */
	static get observedAttributes(): Array<string> {
		return [
			'data-for',
		];
	}

	/**
	 * When disabled clicks will not toggle mute.
	 *
	 * External updates to mute will not update the state here.
	 */
	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value === this.disabled ) {
			return;
		}

		if ( value ) {
			this.setAttribute( 'disabled', '' );

			return;
		}

		this.removeAttribute( 'disabled' );
	}

	attributeChangedCallback( attrName: string, oldVal: string|null, newVal: string|null ): void {
		// Event listeners are added
		if ( 'data-for' === attrName ) {
			this.stopListening( oldVal );

			if ( null !== newVal ) {
				this.startListening( newVal );
			}

			return;
		}
	}

	#clickHandler = ( e: MouseEvent ): void => {
		// always prevent default button behaviour
		e.preventDefault();

		// when disabled do nothing
		if ( this.disabled ) {
			return;
		}

		const media = this.getAttachedMedia();
		if ( !media ) {
			return;
		}

		media.muted = !media.muted;

		if ( media.muted ) {
			this.setAttribute( 'data-muted', '' );

			return;
		}

		this.removeAttribute( 'data-muted' );
	};

	#volumechangeHandler = (): void => {
		requestAnimationFrame( () => {
			this.setStateAttribute();
		} );
	};

	#timeupdateHandler = (): void => {
		requestAnimationFrame( () => {
			this.setStateAttribute();
		} );
	};

	#playPauseHandler = (): void => {
		requestAnimationFrame( () => {
			this.setStateAttribute();
		} );
	};

	connectedCallback(): void {
		this.startListening();
	}

	disconnectedCallback(): void {
		this.stopListening();

		this.removeAttribute( 'data-muted' );
	}

	private startListening( onId?: string|null|undefined ): void {
		this.addEventListener( 'click', this.#clickHandler );

		requestAnimationFrame( () => {
			const media = this.getAttachedMedia( onId );
			if ( media ) {
				if ( media.muted ) {
					this.setAttribute( 'data-muted', '' );
				} else {
					this.removeAttribute( 'data-muted' );
				}

				media.addEventListener( 'timeupdate', this.#timeupdateHandler, {
					once: true,
				} );

				media.addEventListener( 'volumechange', this.#volumechangeHandler );
				media.addEventListener( 'play', this.#playPauseHandler );
				media.addEventListener( 'pause', this.#playPauseHandler );
			}
		} );
	}

	private stopListening( onId?: string|null|undefined ): void {
		this.removeEventListener( 'click', this.#clickHandler );

		const media = this.getAttachedMedia( onId );
		if ( media ) {
			media.removeEventListener( 'volumechange', this.#volumechangeHandler );
			media.removeEventListener( 'play', this.#playPauseHandler );
			media.removeEventListener( 'pause', this.#playPauseHandler );
		}
	}

	private setStateAttribute(): void {
		const media = this.getAttachedMedia();
		if ( !media ) {
			return;
		}

		if ( media.muted ) {
			this.setAttribute( 'data-muted', '' );
		} else {
			this.removeAttribute( 'data-muted' );
		}

		// With a fallback to "true" we set a negative flag.
		this.hasAudio( media ).then( ( hasAudio ) => {
			if ( hasAudio ) {
				this.removeAttribute( 'data-has-no-audio-tracks' );
			} else {
				this.setAttribute( 'data-has-no-audio-tracks', '' );
			}
		} );
	}

	// There is no spec yet for this functionality.
	// We can still determine if there is an audio track in some browsers.
	private hasAudio( media: MutedElement ): Promise<boolean> {
		if ( !( 'HTMLVideoElement' in self ) || !( 'HTMLAudioElement' in self ) ) {
			return Promise.resolve( true );
		}

		if ( media instanceof HTMLAudioElement ) {
			// fallback to true until there is an official API that is widely adopted.
			return Promise.resolve( true );
		}

		if ( !isHasAudioTracksElement( media ) ) {
			// fallback to true until there is an official API that is widely adopted.
			return Promise.resolve( true );
		}

		const video = media as HasAudioTracksElement;

		if ( 'mozHasAudio' in HTMLVideoElement.prototype && 'undefined' !== typeof video.mozHasAudio ) {
			if ( true === video.mozHasAudio ) {
				return Promise.resolve( true );
			}

			return new Promise( ( resolve ) => {
				setTimeout( () => {
					if ( !video.mozHasAudio ) {
						setTimeout( () => {
							resolve( !!video.mozHasAudio );
						}, 500 );

						return;
					}

					resolve( true );
				}, 250 );
			} );
		}

		if ( 'audioTracks' in HTMLVideoElement.prototype ) {
			if ( ( 'undefined' !== typeof video.audioTracks ) && ( 0 < video.audioTracks.length ) ) {
				return Promise.resolve( true );
			}

			return new Promise( ( resolve ) => {
				setTimeout( () => {
					if ( !video.audioTracks || 0 === video.audioTracks.length ) {
						setTimeout( () => {
							if ( !video.audioTracks || 0 === video.audioTracks.length ) {
								resolve( false );

								return;
							}

							resolve( 0 < video.audioTracks.length );
						}, 500 );

						return;
					}

					resolve( 0 < video.audioTracks.length );
				}, 250 );
			} );
		}

		if ( 'webkitAudioDecodedByteCount' in video ) {
			if ( ( 'undefined' !== typeof video.webkitAudioDecodedByteCount ) && ( 0 < video.webkitAudioDecodedByteCount ) ) {
				return Promise.resolve( true );
			}

			return new Promise( ( resolve ) => {
				setTimeout( () => {
					if ( ( 'undefined' === typeof video.webkitAudioDecodedByteCount ) || ( 0 === video.webkitAudioDecodedByteCount ) ) {
						setTimeout( () => {
							if ( ( 'undefined' === typeof video.webkitAudioDecodedByteCount ) || ( 0 === video.webkitAudioDecodedByteCount ) ) {
								resolve( false );

								return;
							}

							resolve( true );
						}, 500 );

						return;
					}

					resolve( true );
				}, 250 );
			} );
		}

		// fallback to true until there is an official API that is widely adopted.
		return Promise.resolve( true );
	}

	private getAttachedMedia( withId?: string|null|undefined ): MutedElement | null {
		const id = withId || this.getAttribute( 'data-for' );
		if ( !id ) {
			return null;
		}

		const media = document.getElementById( id );
		if ( !media || !isMutedElement( media ) ) {
			return null;
		}

		return media;
	}
}


// TODO :
// Is there any advised ARIA attribute that should be toggled on mute?
