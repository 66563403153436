import { prefersReducedMotion } from '@mrhenry/wp--prefers-reduced-motion';

const FALLBACK_PIXELS_PER_SECOND = 64;

type MarqueeAnimationOptions = {
	fullWidth: number;
}

type MarqueeSizes = Array<{
	mediaQuery: MediaQueryList,
	pixelsPerSecond: number
}>;

class Marquee {
	#el: HTMLElement;

	#sizeSim: HTMLElement;

	#text: string;

	#sizes: MarqueeSizes;

	#innerWidth?: number;

	#outerWidth?: number;

	#animation?: Animation;

	#awaitingResetAfterResize = false;

	constructor( el: HTMLElement, text: string, sizes: MarqueeSizes ) {
		this.#el = el;
		this.#text = text;
		this.#sizes = sizes;

		this.#sizeSim = this.createSizeSimulator();

		requestAnimationFrame( () => {
			this.play();
		} );
	}

	createSizeSimulator(): HTMLElement {
		const simContainer = document.createElement( 'DIV' );
		simContainer.className = 'u-visually-hidden';
		simContainer.setAttribute( 'aria-hidden', 'true' );
		simContainer.style.overflow = 'hidden';
		simContainer.style.width = '100%';

		const sim = document.createElement( 'DIV' );
		sim.className = this.#el.className;
		sim.innerHTML = this.#text;

		simContainer.appendChild( sim );
		document.body.appendChild( simContainer );

		return sim;
	}

	async resetAfterResize() {
		if ( this.#awaitingResetAfterResize ) {
			return;
		}

		// Lock
		this.#awaitingResetAfterResize = true;

		// Resets
		this.#outerWidth = undefined;
		this.#innerWidth = undefined;

		// Play
		await this.play();

		// Unlock
		this.#awaitingResetAfterResize = false;
	}

	innerWidth(): number {
		if ( 'undefined' !== typeof this.#innerWidth ) {
			return this.#innerWidth;
		}

		const clientRects = this.#sizeSim.getClientRects();
		if ( !clientRects || !clientRects.length ) {
			return 0;
		}

		this.#innerWidth = clientRects[0].right - clientRects[0].left;

		return this.#innerWidth;
	}

	outerWidth(): number {
		if ( 'undefined' !== typeof this.#outerWidth ) {
			return this.#outerWidth;
		}

		if ( !this.#el.parentElement ) {
			return 0;
		}


		const clientRects = this.#el.parentElement.getClientRects();
		if ( !clientRects || !clientRects.length ) {
			return 0;
		}

		this.#outerWidth = clientRects[0].right - clientRects[0].left;

		return this.#outerWidth;
	}

	pixelsPerSecond(): number {
		const match = this.#sizes.find( ( size ) => {
			return size.mediaQuery.matches;
		} );

		if ( match ) {
			return match.pixelsPerSecond;
		}

		return FALLBACK_PIXELS_PER_SECOND;
	}

	neededRepeatsToFillSpace(): number {
		if ( !this.innerWidth() ) {
			return 1;
		}

		if ( this.innerWidth() > this.outerWidth() ) {
			return 1;
		}

		return Math.ceil(
			this.outerWidth() / this.innerWidth()
		) + 1;
	}

	prepareForAnimation() : MarqueeAnimationOptions {
		const neededRepeats = this.neededRepeatsToFillSpace();
		const fullWidth = neededRepeats * this.innerWidth();
		this.#el.style.textShadow = `${fullWidth}px 0px currentColor`;

		let text = '';
		for ( let i = 0; i < ( neededRepeats ); i++ ) {
			text += this.#text;
		}

		this.#el.innerHTML = text;

		return {
			fullWidth: fullWidth,
		};
	}

	play(): Promise<void> {
		return new Promise( ( resolve ) => {
			if ( this.#animation ) {
				// Stop existing animation by setting iterations to 1.
				// Listen for "finish" event.
				// Restart play.

				this.#animation.effect?.updateTiming( {
					iterations: 1,
				} );

				this.#animation.addEventListener( 'finish', async() => {
					this.#animation = undefined;
					await this.play();
					resolve();
				}, {
					once: true,
				} );


				return;
			}

			// No animation.
			// Just start.
			const animationOptions = this.prepareForAnimation();

			this.#animation = this.#el.animate(
				[
					{
						transform: 'translate3d(0, 0, 0)',
					},
					{
						transform: `translate3d(-${animationOptions.fullWidth}px, 0, 0)`,
					},
				], {
					fill: 'forwards',
					easing: 'linear',
					duration: ( animationOptions.fullWidth / this.pixelsPerSecond() ) * 1000,
					iterations: Infinity,
				}
			);

			resolve();
		} );
	}

	pause() {
		if ( this.#animation ) {
			this.#animation.pause();
		}
	}

	resume() {
		if ( !this.#animation ) {
			this.play();

			return;
		}

		this.#animation.play();
	}
}

const marqueeElements: WeakMap<HTMLElement, Marquee> = new WeakMap();

function initMarquees() {
	if ( prefersReducedMotion() ) {
		// do nothing;
		return;
	}

	( document.querySelectorAll( 'div[marquee]' ) as NodeListOf<HTMLElement> ).forEach( ( el ) => {
		if ( marqueeElements.has( el ) ) {
			return;
		}

		const text = el.getAttribute( 'marquee' );
		if ( !text ) {
			return;
		}

		const pixelsPerSecondRaw = ( el.getAttribute( 'pixels-per-second' ) || '64' ).split( ',' ).map( ( x ) => {
			const i = parseInt( x.trim(), 10 );
			if ( !i || isNaN( i ) ) {
				return 64;
			}

			return i;
		} );

		const sizesRaw = ( el.getAttribute( 'sizes' ) || '(min-width: 1px)' ).split( ',' ).map( ( x ) => {
			return x.trim();
		} );

		const sizes: MarqueeSizes = sizesRaw.map( ( size, index ) => {
			if ( pixelsPerSecondRaw[index] ) {
				return {
					mediaQuery: window.matchMedia( size ),
					pixelsPerSecond: pixelsPerSecondRaw[index],
				};
			}

			return {
				mediaQuery: window.matchMedia( size ),
				pixelsPerSecond: pixelsPerSecondRaw[pixelsPerSecondRaw.length - 1],
			};
		} );


		let pixelsPerSecond = parseInt( el.getAttribute( 'pixels-per-second' ) || `${FALLBACK_PIXELS_PER_SECOND}`, 10 );
		if ( !pixelsPerSecond || isNaN( pixelsPerSecond ) ) {
			pixelsPerSecond = FALLBACK_PIXELS_PER_SECOND;
		}

		const marquee = new Marquee( el, text, sizes );
		marqueeElements.set( el, marquee );
	} );
}

requestAnimationFrame( initMarquees ); // after first render
window.addEventListener( 'load', initMarquees ); // when fully loaded

let resizeThrottle = false;
function resetMarqueesAfterResize() {
	( document.querySelectorAll( 'div[marquee]' ) as NodeListOf<HTMLElement> ).forEach( ( el ) => {
		marqueeElements.get( el )?.resetAfterResize();
	} );
}

window.addEventListener( 'resize', () => {
	if ( resizeThrottle ) {
		return;
	}

	resizeThrottle = true;

	requestAnimationFrame( () => {
		resetMarqueesAfterResize();
		resizeThrottle = false;
	} );
} );

document.addEventListener( 'visibilitychange', () => {
	// pause all marquees
	if ( 'hidden' === document.visibilityState ) {
		( document.querySelectorAll( 'div[marquee]' ) as NodeListOf<HTMLElement> ).forEach( ( el ) => {
			marqueeElements.get( el )?.pause();
		} );

		return;
	}

	// resume all marquees
	if ( 'visible' === document.visibilityState ) {
		( document.querySelectorAll( 'div[marquee]' ) as NodeListOf<HTMLElement> ).forEach( ( el ) => {
			marqueeElements.get( el )?.resume();
		} );
	}

}, false );
