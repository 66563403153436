import { MrDialog } from '@mrhenry/wp--mr-interactive';

class MrInfoDialog extends MrDialog {
	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						transform: 'translateX(100%)',
					},
					{
						transform: 'translateX(0)',
					},
				],
				{
					duration: 640,
					easing: 'cubic-bezier(0.725, 0, 0, 1)',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						transform: 'translateX(0)',
					},
					{
						transform: 'translateX(100%)',
					},
				],
				{
					delay: 0,
					duration: 432,
					easing: 'cubic-bezier(0.65, 0, 0, 1)',
					fill: 'forwards',
				}
			),
		];
	}

	override async didOpen() {
		await super.didOpen();

		if ( matchMedia( '(max-width: 1024px)' ).matches ) {
			document.body.style.overflow = 'hidden';
		}
	}

	override async willClose() {
		await super.willClose();

		if ( document.body.style.overflow ) {
			document.body.style.overflow = '';
		}
	}
}

customElements.define( 'mr-info-dialog', MrInfoDialog );
