import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrOverlay extends MrModalDialog {
	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-overlay' );

		this.querySelectorAll( 'img[loading="lazy"]' ).forEach( ( img ) => {
			img.setAttribute( 'loading', 'eager' );
		} );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-overlay' );
	}

	override firstFocusableElement(): HTMLElement | void {
		if ( !this.parentNode ) {
			return this;
		}

		const firstFocusableChild = this.querySelector( 'nav a' );
		if ( firstFocusableChild && firstFocusableChild instanceof HTMLElement ) {
			firstFocusableChild.setAttribute( 'data-forced-focus', '' );

			const removeForcedFocus = () => {
				firstFocusableChild.removeAttribute( 'data-forced-focus' );
				firstFocusableChild.removeEventListener( 'blur', removeForcedFocus );
			};

			firstFocusableChild.addEventListener( 'blur', removeForcedFocus, {
				once: true,
			} );

			return firstFocusableChild;
		}

		return this;
	}
}

customElements.define( 'mr-overlay', MrOverlay );
