import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';
import { PrivacyControls, initMenuButtons } from '@mrhenry/wp--mr-interactive';

PrivacyControls.setupPrivacyAwareEmbedders();
PrivacyControls.setupPrivacyAwareGTM();

initMenuButtons();

import './modules/accordion';
import './modules/anchor-state';
import './modules/auto-grow-textarea';
import './modules/carousel';
import './modules/info-dialog';
import './modules/input-sink';
import './modules/landing-hero';
import './modules/marquee';
import './modules/mr-person-detail';
import './modules/overlay';
import './modules/privacy-dialog';
import './modules/transparent-header-toggle';
import './modules/video-player';
